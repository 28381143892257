import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="social-media-icons">
          <a
            href="https://www.facebook.com/amendoadecacau/"
            className="fb-ic"
            target="blank"
          >
            <i className="footer fab fa-facebook-f fa-lg mr-md-5 mr-5 fa-2x" />
          </a>
          <a href="http://twixar.me/l8zn" className="gplus-ic" target="blank">
            <i className="footer fab fa-google-plus-g fa-lg mr-md-5 mr-5 fa-2x" />
          </a>
          <a
            href="https://www.instagram.com/amendoadecacau/"
            className="ins-ic"
            target="blank"
          >
            <i className="footer fab fa-instagram fa-lg mr-md-2 mr-5 fa-2x" />
          </a>
        </div>
        <div className="copyright">
          <span>© Made By: </span>
          <a href="https://github.com/carloseustaquio" target="_blank">
            Carlos Eustáquio
          </a>
        </div>
      </div>
    </div>
  );
}
