import React from 'react';
import Slide from '../components/slide';
import Card from '../components/card';

const slidesHome = [
  { 
    id: "home-1",
    src: "/assets/slide/mao-com-cacau-4x3.webp",
    alt: "First slide",
    h1: "Um novo conceito de chocolates",
    h2: "Diga oi ao rústico.",
  },
  {         
    id: "home-2",
    src: "/assets/slide/caixa-na-mao-4x3.webp",
    alt: "Second slide",
    h1: "Somos uma marca brasileira de cacau e chocolates rústicos",
    h2: null,
  },
  {
    id: "home-3",
    src: "/assets/slide/circulo-cacau-4x3.webp",
    alt: "Third slide",
    h1: "Tree-to-bar",
    h2: "Desde a planta até à barra",
  },
]

export default function Home(props) {

  function handleClick(e) {
    props.history.push(e);
  }

  return (
    <>
      <Slide slides={slidesHome}/>

      <div className="content-container">
        <h1>Qualidade que começa pela matéria-prima</h1>    
        <h2>Cacau de altitude, plantado à sombra da mata Atlântica, no sul da Bahia.</h2>
        <img src="/assets/caixa-na-mao.webp" alt=""></img>
        <p>O verde de nossa embalagem remete à bela floresta de onde tem origem nosso cacau, que é
          cultivado sem qualquer utilização de adubos químicos ou defensivos agrícolas.
        </p>
      </div>
      <div className="cards-dock">
        <Card
          title="Quem somos?"
          text="Somos uma marca brasileira. Produzimos chocolates artesanais e cultivamos cacau sem defensivos agrícolas ou adubos químicos."
          imgSrc="/assets/logo-amendoadecacau-1-4x3_370w.webp"
          linkClick={() => handleClick("/quem-somos")}
        />      
        <Card
          title="Nossos Produtos"
          text="Conheca os nossos chocolates rústicos e também a forma de venda do cacau."
          imgSrc="/assets/produtos-4x3.webp"
          linkClick={() => handleClick("/nossos-produtos")}  
        />      
        <Card
          title="Nossa Propriedade"
          text="Conheca a Fazenda Eustáquio. Há mais de 1000m de altitude, de onde vem nosso cacau."
          imgSrc="/assets/cacau-na-arvore-4x3.webp"
          linkClick={() => handleClick("/nossa-fazenda")}
        />      
      </div>
    </>
  );
}
