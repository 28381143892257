import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

export default function Header() {

  const [isOpen, setIsOpen] = useState(false);

  function closeNav() {
    setIsOpen(false);
  };

  return (
    <>
    <Navbar expanded={isOpen} fixed="top" expand="md" bsClass="navbar"> 
      <Navbar.Brand>
        <Link onClick={closeNav} to="/">
          <img id="logo-header" src="/assets/logo-a.webp" alt="logo amêndoa de cacau"/>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle onClick={() => setIsOpen(!isOpen)} aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={closeNav}> 
            <Link to="/quem-somos">Quem Somos</Link>
          </Nav.Link>
          <Nav.Link onClick={closeNav}>
            <Link  to="/nossos-produtos">Nossos Produtos</Link>
          </Nav.Link>
          <Nav.Link onClick={closeNav}>
            <Link to="/nossa-fazenda">Nossa Fazenda</Link>
          </Nav.Link>
          <Nav.Link onClick={closeNav}>
            <Link to="/contato">Contato</Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <div id="navbar-space"/>
    </>
  )
}
