import React from 'react';
import { Carousel } from 'react-bootstrap';

export default function HomeSlide(props) {
  return (
    <Carousel 
      className={`${props.page}`}
      controls={props.controls}
      indicators={props.indicators}
      interval={props.interval}
    >
      {props.slides.map( e =>
        <Carousel.Item>
          <img
            className={`d-block w-100`}
            src={e.src}
            alt={e.alt}
          />
          <Carousel.Caption>
            <div id={`${e.id}`}>
              <h1>{e.h1}</h1>
              <h2>{e.h2}</h2>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      )}
    </Carousel>
  );
}
