import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

export default function Contato() {
  
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    sent: false,
    buttonText: 'Enviar',
    invalidForm: null,
  });

  const [validated, setValidated] = useState(false);
  const [invalidForm, setInvalidForm] = useState(false);

  const handleValidate = e => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setInvalidForm(true)
    }
    else {
      e.preventDefault();
      handleSubmit();
    }
    setValidated(true);
  };

  function handleChange(e) {
    setState({...state, [e.target.name]: e.target.value})
  }

  function handleSubmit() {
    setState({...state, buttonText: 'Enviando...'})
        
    let data = {
      name: state.name,
      email: state.email,
      subject: state.subject,
      message: state.message,
    }
    
    axios.post('https://amendoadec-back.carloseustaquio.now.sh/api/v1', data)
    .then( res => {
      setState({ 
        sent: true,
        buttonText: 'Enviado!',
      }, resetForm())
    })
    .catch( () => {
      console.log('Message not sent')
    })
  }

  function resetForm() {
    setState({
      name: '',
      email: '',
      subject: '',
      message: '',
      buttonText: 'Enviado!',
    })
  }

  return (
    <div className="content-container contato">
      <h1>Entre em contato conosco:</h1>
      <img id="contato-img" src="/assets/logo-amendoadecacau-1-redondo.webp" alt="logomarca amendoa de cacau" />
      <Form noValidate validated={validated} onSubmit={handleValidate}>
        <Form.Group controlId="name">
          <Form.Label>Nome:</Form.Label> 
          <Form.Control required onChange={handleChange} name="name" type="text"></Form.Control>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email:</Form.Label> 
          <Form.Control required onChange={handleChange} name="email" type="email"></Form.Control>
        </Form.Group>
        <Form.Group controlId="subject">
          <Form.Label>Assunto:</Form.Label> 
          <Form.Control onChange={handleChange} name="subject" type="text"></Form.Control>
        </Form.Group>
        <Form.Group controlId="message">
          <Form.Label>Mensagem:</Form.Label> 
          <Form.Control required onChange={handleChange} as="textarea" rows="5" name="message" type="text"></Form.Control>
        </Form.Group>
        <Button variant="success" type="submit">
          {state.buttonText}
        </Button>
        {invalidForm ? <span>*preencha todos os campos obrigatórios</span> : null}
      </Form>
    </div>
  );
}