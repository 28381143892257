import React from "react";
import { Card, Button } from "react-bootstrap";

export default function MyCard({ imgSrc, title, text, linkClick }) {
  return (
    <Card>
      <Card.Img variant="top" src={imgSrc} />
      <Card.Body>
        <Card.Title bsClass="card-title">{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        <Button onClick={linkClick} variant="success">
          Ver página
        </Button>
      </Card.Body>
    </Card>
  );
}
