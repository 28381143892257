import React from 'react';
import ProductCard from '../components/product-card';
import { Button } from 'react-bootstrap';
const produtos = [
  {
    imgs: [
      {src: "/assets/produtos/chocolate-display.webp"},
      {src: "/assets/produtos/chocolate-coco-display.webp"},
      {src: "/assets/produtos/barrinha-1.webp"},
      {src: "/assets/produtos/chocolate-150g.webp"},
      {src: "/assets/produtos/150g.webp"},
    ],
    alt: "display chocolate",
    h1: "Chocolate Rústico",
    h2: "com leite de coco",
    h3: "Chocolate artesanal feito com amêndoa de cacau, leite de coco e açúcar demerara.",
    p: "Display: contém 35 unidades de 20g cada.",
    p2: "Caixa: 150g de chocolate.",
  },
  {
    imgs: [
      {src: "/assets/produtos/chocolate-display.webp"},
      {src: "/assets/produtos/chocolate-slac-display.webp"},
      {src: "/assets/produtos/barrinha-1.webp"},
      {src: "/assets/produtos/chocolate-150g.webp"},
      {src: "/assets/produtos/150g.webp"},
    ],
    alt: "display chocolate",
    h1: "Chocolate Rústico",
    h2: "com leite sem lactose",
    h3: "Chocolate artesanal feito com amêndoa de cacau, leite sem lactose e açúcar demerara.",
    p: "Display: contém 35 unidades de 20g cada, embalados individualmente.",
    p2: "Caixa: 150g de chocolate.",
  },
  {
    imgs: [
      {src: "/assets/produtos/amendoa-180g.webp"},
      {src: "/assets/produtos/amendoa-cacau.webp"},
    ],
    alt: "amendoa de cacau",
    h1: "Amêndoa de Cacau",
    h2: "descascadas, inteiras.",
    h3: "Nossas amêndoas selecionadas inteiras e descascadas.",
    p: "Caixa com 180g.",
    p2: "Embalagem com 1kg.",
  },
  {
    imgs: [
      {src: "/assets/produtos/cha-de-cacau.webp"},
    ],
    alt: "chá de cacau",
    h1: "Chá de Cacau",
    h3: "Feito com a casca da amêndoa, este chá com sabor de chocolate impressiona a todos que o provam!",
    p: "Pacote com 40g",
  },
  {
    imgs: [
      {src: "/assets/produtos/achocolatado-400g.webp"},
    ],
    alt: "achocolatado de chocolate rústico",
    h1: "Achocolatado",
    h2: "de Chocolate Rústico",
    h3: "Incrível para ser usado em receitas!",
    p: "Caixa com 400g",
  },

]

export default function NossosProdutos(props) {

  function handleClick() {
    props.history.push("/contato");
  }

  return (
    <div className="content-container page-nossos-produtos">
      <h1>Nossos Produtos:</h1>
      <p>Nossos produtos <strong>não</strong> possuem lactose, 
      glúten ou qualquer tipo de conservantes.</p>
      <ProductCard 
        items={produtos}
      />
      <div id="ficou-interessado">
        <p><strong>Ficou interessado?</strong></p>
        <span>Para saber mais sobre nossos preços e sobre revenda 
          de nossos produtos entre em contato conosco!</span>
        <br/>
        <Button 
          id="contato-btn" 
          variant="success"
          onClick={handleClick}
          >Contato</Button>
      </div>
    </div>
  );
}
