import React from 'react';
import Slide from '../components/slide';

export default function NossaFazenda() {
  return (
    <div className="content-container">
      <h1>De onde vem nosso cacau?</h1>
      <Slide 
      page="nossa-fazenda"
      slides={[
        {
          src: "/assets/cacau-na-arvore.webp",
          alt: "cacau na arvore",
        },
        {
          src: "/assets/cacau-e-trabalhador.webp",
          alt: "cacau na arvore",
        },
        {
          src: "/assets/fazenda-barcaca.webp",
          alt: "cacau na arvore",
        },
      ]}
      />
      <p> 
        Nossa propriedade rural 
        fica situada no sul da Bahia, próximo ao município de 
        Jussari, e à reserva da Serra do Teimoso. É uma região
        de Mata Atlântica muito preservada, e de altitude elevada 
        (chegando a, aproximadamente, a 1000 metros acima do nível 
        do mar), o que faz com que o nosso cacau cresca em um ambiente 
        propício ao seu bom desenvolvimento e qualidade.
      </p>
      <p>
        Por ser uma àrvore de pequeno porte
        e necessitar de sombra constante, o cacaueiro é uma excelente 
        cultura parceira na preservação das florestas tropicais, como a 
        Mata Atlântica. Pois o seu cultivo gera riqueza para a região
        sem a necessidade de desmatamento, pelo contrário, preservando
        natureza.
      </p>
      <p>
        Nossos pés de cacau, por exemplo, crescem 
        à sombra de árvores centenárias, como jequitibás e ipês rosa.
      </p>
      <p>
        Além disso, o nosso cacau é cultivado de
        maneira totalmente <strong>ecológica</strong>, sem o uso de defensivos agrícolas 
        ou adubos químicos.
      </p>
    </div>
  );
}