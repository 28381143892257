import React from 'react';

export default function QuemSomos() {
  return (
    <div className="content-container" >
      <h1>Quem é a Amêndoa D'Cacau?</h1>    
      <img src="/assets/logo-amendoadecacau-1-4x3_604w.webp" alt="logomarca amendoa de cacau" />
      <p>Somos uma empresa brasileira produtora de cacau 
      e chocolates artesanais. Trabalhamos há 10 anos na área, e há 5 produzimos no 
      processo tree-to-bar (da-árvore-à-barra), onde temos todo o controle da produção de 
      nossos chocolates desde o plantio até a venda. 
      </p>
      <p>
        Nossa fazenda fica na região do Sul da Bahia, e todo o nosso cacau é produzido sem a 
        utilização de defensivos agrícolas ou adubos químicos.
      </p>
      <p>
        Nossa missão é produzir amêndoas de altíssima qualidade, e, para tal, realizamos seleções 
        de até 6 etapas. Tudo para obter um cacau superior em características de aroma, sabor, tamanho e cor.
      </p> 
      <p> 
        Nós comercializamos o cacau e chocolate em vários dos seus estágios de processamento.
        Desde as amêndoas de cacau selecionadas, amêndoas descascadas inteiras, o nibs, o chocolate, 
        até o achocolatado - excelente para receitas.
      </p> 
    </div>
  );
}
