import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

import './sass/app.scss';

import Header from './components/header';
import Footer from './components/footer';
import ScrollToTop from './components/scroll-top';

export default function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Header />
            <Routes />
          <Footer />
          <ScrollToTop />
        </BrowserRouter>
      </div>
    </>
  )
}
