import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import Slide from '../components/slide'

export default function Acordion(props) {
  return (
    <>
      {props.items.map((e, i) =>
        <Card className="horizontal-card">
          <Card.Body>
            <Card.Title>{e.h1}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{e.h2}</Card.Subtitle>
            <Card.Text>
              <p>{e.h3}</p>
              <p>
                <strong>Embalagens:</strong> <br />
                {e.p} <br />
                {e.p2}
              </p>
            </Card.Text>
          </Card.Body>
          <Slide
            slides={e.imgs}
            indicators={true}
            controls={false}
            interval={Math.floor(Math.random() * 1000) + 2000}//{3000}
          />
        </Card>
      )}
    </>
  );
}
