import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/home';
import QuemSomos from './pages/quem-somos';
import NossosProdutos from './pages/nossos-produtos';
import NossaFazenda from './pages/nossa-fazenda';
import Contato from './pages/contato';

export default function Routes() {
  return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/quem-somos" component={QuemSomos} />
        <Route exact path="/nossos-produtos" component={NossosProdutos} />
        <Route exact path="/nossa-fazenda" component={NossaFazenda} />
        <Route exact path="/contato" component={Contato} />
      </Switch>
  );
}
